import React, {useState} from 'react'
import { Link } from 'react-router-dom'

export default function Home({ basename }) {
  return (
    <div>
      <center>
      <Link to="/"><img src={`${basename}/images/MetaverseCoin2.png`} className="header-banner" alt="" /></Link>
      <br /><br />
      <h1>Metaverse Coin</h1>
      <h2>Official Marketplace</h2>
      <br />
      <div className="divTable" style={{ marginBottom: '3rem' }}>
        <div className="divTableRow">
          <div className="divTableCell">
            <h3>Blue Dragon</h3>
              <Link to="collections/blue-dragon"><CollectionImage imageUrl={`${basename}/images/collections/blue-dragon/blue-dragon-collection.png`} /></Link>
            <div className="divTable" style={{ textAlign: "center", border: "0px solid #fff" }}>
              <div className="divTableRow">
                  <div className="divTableCell"><p>COMMON<br />100,000 mints<br />1 MTVRS each</p></div>
              </div>
            </div>
          </div>
          <div className="divTableCell">
            <h3>Black Dragon</h3>
              <Link to="collections/black-dragon"><CollectionImage imageUrl={`${basename}/images/collections/black-dragon/black-dragon-collection.png`} /></Link>
            <div className="divTableCell"><p>UNCOMMON<br />10,000 mints<br />2 MTVRS each</p></div>
          </div>
          <div className="divTableCell">
            <h3>Purple Dragon</h3>
              <Link to="collections/purple-dragon"><CollectionImage imageUrl={`${basename}/images/collections/purple-dragon/purple-dragon-collection.png`} /></Link>
            <div className="divTable" style={{ textAlign: "center", border: "0px solid #fff" }}>
              <div className="divTableRow">
                  <div className="divTableCell"><p>RARE<br />5,000 mints<br />2.5 MTVRS each</p></div>
              </div>
            </div>
          </div>
          <div className="divTableCell">
            <h3>Green Dragon</h3>
              <Link to="collections/green-dragon"><CollectionImage imageUrl={`${basename}/images/collections/green-dragon/green-dragon-collection.png`} /></Link>
            <div className="divTable" style={{ textAlign: "center", border: "0px solid #fff" }}>
              <div className="divTableRow">
                <div className="divTableCell"><p>EPIC<br />1,000 mints<br />3 MTVRS each</p></div>
              </div>
            </div>
          </div>
          <div className="divTableCell">
            <h3>Red Dragon</h3>
              <Link to="collections/red-dragon"><CollectionImage imageUrl={`${basename}/images/collections/red-dragon/red-dragon-collection.png`} /></Link>
            <div className="divTable" style={{ textAlign: "center", border: "0px solid #fff" }}>
              <div className="divTableRow">
                  <div className="divTableCell"><p>LEGENDARY<br />100 mints<br />4 MTVRS each</p></div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="divTableRow">
            <div className="divTableCell">
              <h3>Tiger</h3>
              <Link to="collections/tiger"><CollectionImage imageUrl={`${basename}/images/collections/tiger/tiger-collection.png`} /></Link>
            <div className="divTableCell"><p>COMMON<br />100,000 mints<br />1 MTVRS each</p></div></div>
            <div className="divTableCell"><h3>White Tiger</h3>
              <Link to="collections/white-tiger"><CollectionImage imageUrl={`${basename}/images/collections/white-tiger/white-tiger-collection.png`} /></Link>
            <div className="divTableCell"><p>UNCOMMON<br />10,000 mints<br />2 MTVRS each</p></div></div>
            <div className="divTableCell"><h3>Phoenix</h3>
              <Link to="collections/phoenix"><CollectionImage imageUrl={`${basename}/images/collections/phoenix/phoenix-collection.png`} /></Link>
            <div className="divTableCell"><p>RARE<br />5,000 mints<br />2.5 MTVRS each</p></div></div>
            <div className="divTableCell"><h3>Gryphon</h3>
              <Link to="collections/gryphon"><CollectionImage imageUrl={`${basename}/images/collections/gryphon/gryphon-collection.png`} /></Link>
            <div className="divTableCell"><p>EPIC<br />1,000 mints<br />3 MTVRS each</p></div></div>
            <div className="divTableCell"><h3>NFT World 2021</h3>
              <Link to="collections/nft-world-2021"><CollectionImage imageUrl={`${basename}/images/collections/nft-world-2021/nft-world-2021-collection.png`} /></Link>
            <div className="divTableCell"><p>(various)</p></div></div>
        </div>
      </div>

      {/* <blockquote className="twitter-tweet">
        <p lang="en" dir="ltr">CMC application verification for Metaverse Coin (MTVRS):<br />Ticket ID:360000493112</p>
        &mdash; NFT World (@NFT_World) <a href="https://twitter.com/NFT_World/status/1511813369233174530?ref_src=twsrc%5Etfw">April 6, 2022</a>
      </blockquote> */}
      </center>
    </div>
  )
}

const CollectionImage = ({ imageUrl }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
      <div style={{ height: '11vw', width: '11vw', display: 'grid', placeItems: 'center' }}>
        {!imageLoaded && <div className="lds-dual-ring"></div>}
        <img hidden={!imageLoaded} onLoad={() => setImageLoaded(true)} onError={() => setImageLoaded(false)} src={imageUrl} className="collection-image" alt="" />
      </div>
  )
}