import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import AdminPanelIcon from '@mui/icons-material/AdminPanelSettings'
import Button from '@mui/material/Button'

import icon from '../Assets/icon.png'
import WalletContext from '../Context/wallet'

const WalletButton = styled(Button)({ textTransform: 'none' })

export default function Header ({ basename }) {
    const { wallet, connectWallet, switchNetwork } = useContext(WalletContext)
    const [displayAddress, setDisplayAddress] = useState(wallet.address)

    useEffect(() => {
      (async () => {
        if (!wallet.address) return setDisplayAddress(null)
        setDisplayAddress(wallet.address.substr(0, 6) + '..' + wallet.address.slice(-4))
      })()
    }, [wallet])

    return (
        <div>
            <div className="top-menu">
              {
                wallet.adminAddresses.includes(wallet.address?.toUpperCase()) &&
                  <Link to='/admin' style={{ color: 'white', float: 'right', marginLeft: '1.5rem', marginTop: '-5px' }}>
                    <AdminPanelIcon />
                  </Link>
              }

              {/* {!wallet.connected && <button className="button connectbutton" onClick={connectWallet}>Connect</button>} */}
              {!wallet.connected && <WalletButton className='btn connectbutton' variant='contained' onClick={connectWallet}>Connect</WalletButton>}

              {
                wallet.connected && wallet.chainId !== 137 &&
                  <WalletButton className='btn connectbutton' variant='contained' onClick={switchNetwork}>Switch to Polygon</WalletButton>
              }

              {
                wallet.connected && wallet.chainId === 137 &&
                  <a
                    className='wallet-link'
                    target='_blank'
                    rel='noreferrer'
                    href={`https://polygonscan.com/token/${wallet.contracts.mtvrs.address}?a=${wallet.address}`}
                  >
                    {
                      displayAddress &&
                        <span>
                          {displayAddress}
                          <span style={{ marginLeft: '0.5rem', marginRight: '0.25rem' }}>
                            <img src={icon} alt='&bull;' style={{ height: '0.8vw' }} />
                          </span>
                        </span>
                    }

                    <span>
                      {!wallet.balance && <span style={{ color: 'gray' }}>Checking balance...</span>}
                      {
                        (wallet.balance || wallet.optimisticBalance) &&
                          <strong>
                            <span className={`wallet-balance`}>{wallet.optimisticBalance || wallet.balance}</span>
                            <span style={{ color: 'lightgreen' }}>$</span>
                            MTVRS
                          </strong>
                      }
                    </span>
                  </a>
              }
            </div>

            <Link to={`${basename}/`}>
              <img src={`${basename}/images/MTVRS-Round.jpg`} style={{ left: "0", position: "absolute" }} className="mtvrs-round" alt="" />
            </Link>
            <br />
        </div>
    )
}
