import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        getAccountTokens: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          }
        }
      }
    }
  }
}

const apolloClients = {
  collections: new ApolloClient({ uri: process.env.REACT_APP_SUBGRAPH_COLLECTIONS_URL, cache: new InMemoryCache(cacheOptions) })
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClients.collections}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
