import { ethers } from 'ethers'

import BlueDragonABI from './abi/BlueDragonABI'
import BlackDragonABI from './abi/BlackDragonABI'
import PurpleDragonABI from './abi/PurpleDragonABI'
import GreenDragonABI from './abi/GreenDragonABI'
import RedDragonABI from './abi/RedDragonABI'
import TigerABI from './abi/TigerABI'
import WhiteTigerABI from './abi/WhiteTigerABI'
import PhoenixABI from './abi/PhoenixABI'
import GryphonABI from './abi/GryphonABI'
import NFTWorld2021ABI from './abi/NFTWorld2021ABI'

export const collections = [
  {
    name: 'Blue Dragon',
    safeName: 'blue-dragon',
    address: '0x845f6d0c4b9c2596ec1c2b11bebd5bc43fe2fd59',
    abi: BlueDragonABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'COMMON', imageUrl: `/images/collections/blue-dragon/blue-dragon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'COMMON', imageUrl: `/images/collections/blue-dragon/blue-dragon-upper-body.png` },
      { itemId: 3, item: 'Lower Body', itemRarity: 'COMMON', imageUrl: `/images/collections/blue-dragon/blue-dragon-lower-body.png` },
      { itemId: 1, item: 'Feet', itemRarity: 'COMMON', imageUrl: `/images/collections/blue-dragon/blue-dragon-feet.png` }
    ]
  },

  {
    name: 'Purple Dragon',
    safeName: 'purple-dragon',
    address: '0x8e8c28320e1492044038a6ea0658c745f167a22a',
    abi: PurpleDragonABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'RARE', imageUrl: `/images/collections/purple-dragon/purple-dragon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'RARE', imageUrl: `/images/collections/purple-dragon/purple-dragon-upper-body.png` },
      { itemId: 3, item: 'Lower Body', itemRarity: 'RARE', imageUrl: `/images/collections/purple-dragon/purple-dragon-lower-body.png` },
      { itemId: 1, item: 'Feet', itemRarity: 'RARE', imageUrl: `/images/collections/purple-dragon/purple-dragon-feet.png` }
    ]
  },

  {
    name: 'Green Dragon',
    safeName: 'green-dragon',
    address: '0x13166638ad246fc02cf2c264d1776aefc8431b76',
    abi: GreenDragonABI,
    parts: [
      { itemId: 3, item: 'Head', itemRarity: 'EPIC', imageUrl: `/images/collections/green-dragon/green-dragon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'EPIC', imageUrl: `/images/collections/green-dragon/green-dragon-upper-body.png` },
      { itemId: 1, item: 'Lower Body', itemRarity: 'EPIC', imageUrl: `/images/collections/green-dragon/green-dragon-lower-body.png` },
      { itemId: 0, item: 'Feet', itemRarity: 'EPIC', imageUrl: `/images/collections/green-dragon/green-dragon-feet.png` }
    ]
  },

  {
    name: 'Red Dragon',
    safeName: 'red-dragon',
    address: '0xa26d1da82dfd702d49f5a7b6259e706a488daf82',
    abi: RedDragonABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'LEGENDARY', imageUrl: `/images/collections/red-dragon/red-dragon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'LEGENDARY', imageUrl: `/images/collections/red-dragon/red-dragon-upper-body.png` },
      { itemId: 3, item: 'Lower Body', itemRarity: 'LEGENDARY', imageUrl: `/images/collections/red-dragon/red-dragon-lower-body.png` },
      { itemId: 1, item: 'Feet', itemRarity: 'LEGENDARY', imageUrl: `/images/collections/red-dragon/red-dragon-feet.png` }
    ]
  },

  {
    name: 'Black Dragon',
    safeName: 'black-dragon',
    address: '0x5cf0bc02a9b32242806fb313fa1f16d3fe8d86fa',
    abi: BlackDragonABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/black-dragon/black-dragon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/black-dragon/black-dragon-upper-body.png` },
      { itemId: 3, item: 'Lower Body', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/black-dragon/black-dragon-lower-body.png` },
      { itemId: 1, item: 'Feet', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/black-dragon/black-dragon-feet.png` }
    ]
  },

  {
    name: 'Tiger',
    safeName: 'tiger',
    address: '0xb562f8a1418b9c3aa2f819152cabfdf984ce9074',
    abi: TigerABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'COMMON', imageUrl: `/images/collections/tiger/tiger-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'COMMON', imageUrl: `/images/collections/tiger/tiger-upper-body.png` },
      { itemId: 3, item: 'Lower Body', itemRarity: 'COMMON', imageUrl: `/images/collections/tiger/tiger-lower-body.png` },
      { itemId: 1, item: 'Feet', itemRarity: 'COMMON', imageUrl: `/images/collections/tiger/tiger-feet.png` }
    ]
  },

  {
    name: 'White Tiger',
    safeName: 'white-tiger',
    address: '0x4c573c38f450620628a6a9e78a649f03fc186767',
    abi: WhiteTigerABI,
    parts: [
      { itemId: 3, item: 'Head', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/white-tiger/white-tiger-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/white-tiger/white-tiger-upper-body.png` },
      { itemId: 1, item: 'Lower Body', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/white-tiger/white-tiger-lower-body.png` },
      { itemId: 0, item: 'Feet', itemRarity: 'UNCOMMON', imageUrl: `/images/collections/white-tiger/white-tiger-feet.png` }
    ]
  },

  {
    name: 'Phoenix',
    safeName: 'phoenix',
    address: '0x070fa43d0fcbec94494b0c66104edbe96ec913d1',
    abi: PhoenixABI,
    parts: [
      { itemId: 1, item: 'Head', itemRarity: 'RARE', imageUrl: `/images/collections/phoenix/phoenix-head.png` },
      { itemId: 3, item: 'Upper Body', itemRarity: 'RARE', imageUrl: `/images/collections/phoenix/phoenix-upper-body.png` },
      { itemId: 2, item: 'Lower Body', itemRarity: 'RARE', imageUrl: `/images/collections/phoenix/phoenix-lower-body.png` },
      { itemId: 0, item: 'Feet', itemRarity: 'RARE', imageUrl: `/images/collections/phoenix/phoenix-feet.png` }
    ]
  },

  {
    name: 'Gryphon',
    safeName: 'gryphon',
    address: '0x41fdf5a54eecffd92362777b713db431fd5bc00c',
    abi: GryphonABI,
    parts: [
      { itemId: 0, item: 'Head', itemRarity: 'EPIC', imageUrl: `/images/collections/gryphon/gryphon-head.png` },
      { itemId: 2, item: 'Upper Body', itemRarity: 'EPIC', imageUrl: `/images/collections/gryphon/gryphon-upper-body.png` },
      { itemId: 1, item: 'Lower Body', itemRarity: 'EPIC', imageUrl: `/images/collections/gryphon/gryphon-lower-body.png` }
    ]
  },

  {
    name: 'NFT World 2021',
    safeName: 'nft-world-2021',
    address: '0xcdb25c0ef65acbca8d592083b71259701fbd8fc9',
    abi: NFTWorld2021ABI,
    parts: [
      { itemId: 0, item: 'Shirt', itemRarity: 'RARE', imageUrl: `/images/collections/nft-world-2021/nft-world-shirt.png` },
      { itemId: 1, item: 'Hat', itemRarity: 'EPIC', imageUrl: `/images/collections/nft-world-2021/ethereum-logo-hat.png` },
      { itemId: 2, item: 'Hat', itemRarity: 'LEGENDARY', imageUrl: `/images/collections/nft-world-2021/nfts-hat.png` }
    ]
  }
]

// ---------------------------------------------------------------------------------

export function getContracts (signer) {
  return collections.map(collection => {
    const contract = new ethers.Contract(collection.address, collection.abi, signer)
    contract.collectionData = collection
    return contract
  })
}

export function getCollections (basename = '') {
  return collections.map(collection => {
    collection.parts = collection.parts.map(p => {
      p.imageUrl = `${basename}${p.imageUrl}`
      return p
    })

    return collection
  })
}
