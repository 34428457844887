import { PureComponent } from 'react'

export default class TopNav extends PureComponent {
    render() {
        return (
            <div>
                <div className="top-nav">
                    <a href="https://mtvrs.com/">Home</a> 
                    <a href="https://mtvrs.com/claim">Claim</a> 
                    <a href="https://mtvrs.com/events">Events</a> 
                    <a href="https://market.mtvrs.com/">Marketplace</a> 
                </div>
            </div>
        )
    }
}
