import { createRef, useContext, useEffect, useState } from 'react'
import { getFirestore, collection as fireCollection, collectionGroup as fireCollectionGroup, getDocs, query } from 'firebase/firestore'
import { getDatabase, onValue, ref } from 'firebase/database'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { ethers } from 'ethers'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'

import FirebaseConfig from '../../Config/firebase'
import WalletContext from '../../Context/wallet'
import classes from './Admin.module.css'
import NewUser from './NewUser'
import UserActions from './UserActions'

const firebase = initializeApp(FirebaseConfig)
const db = getFirestore(firebase)
const rtdb = getDatabase(firebase)

export default function Admin () {
  const Sweet = withReactContent(Swal)
  const { wallet } = useContext(WalletContext)

  const getBalanceAddress = createRef()
  const transferAddress = createRef()
  const transferAmount = createRef()

  const [allUsers, setAllUsers] = useState()
  const [displayUsers, setDisplayUsers] = useState()

  // const [discordUsers, setDiscordUsers] = useState()
  const [userColumns, setUserColumns] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [events, setEvents] = useState([])
  const [eventIDs, setEventIDs] = useState([])
  const [rtdbEvents, setRtdbEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState('')
  const [transferResult, setTransferResult] = useState()
  const [getBalanceResult, setGetBalanceResult] = useState()
  const [collectionResults, setCollectionResults] = useState({})
  const [collectionData, setCollectionData] = useState({})
  const [showNewUserDialog, setShowNewUserDialog] = useState(false)
  const [userlistExpanded, setUserlistExpanded] = useState(true)

  useEffect(() => {
    (async() => {
      const auth = getAuth()
      if (auth.currentUser) return

      try {
        await signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_USER_EMAIL, process.env.REACT_APP_FIREBASE_USER_PASSWORD)
      } catch (err) {
        console.error(err)
        Sweet.fire({ icon: 'error', title: 'Firebase Authentication Error', text: err.message })
      }
    })()
  }, []) // eslint-disable-line

  useEffect(() => {
    (async() => {
      onValue(ref(rtdb, '/events'), snapshot => {
        setRtdbEvents(snapshot.val().map((e, id) => ({ ...e, id })))
      })
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        // TODO: Merge everythinto the mtvrs rtdb - started dev on separate project and firestore
        if (allUsers) return
        setDisplayUsers(null)

        const userCollection = fireCollection(db, process.env.REACT_APP_FIREBASE_USER_COLLECTION)
        const userSnapshot = await getDocs(userCollection)
        const userList = userSnapshot.docs.map(user => ({ ...user.data(), id: user.id }))
        const eventsGroup = fireCollectionGroup(db, 'events')
        const eventsQuery = query(eventsGroup)
        const eventsSnapshot = await getDocs(eventsQuery)
        const eventsList = eventsSnapshot.docs
          .filter(doc => doc.ref.path.split('/')[0] === process.env.REACT_APP_FIREBASE_USER_COLLECTION)
          .map(doc => ({ ...doc.data(), id: doc.id, address: doc.ref.parent.parent.id }))
        const eventIds = [...new Set(eventsList.map(doc => doc.id).sort())]

        for (const user of userList) {
          const events = eventsList.filter(evt => evt.address === user.address)
          user.name = events.slice(-1)[0].username
        }

        setEvents(eventsList)
        setEventIDs(eventIds)
        // setEventIDs(rtdbEvents.map(e => e.id))
        setAllUsers(userList)
        setDisplayUsers(userList)
      } catch (err) {
        console.error(err)
        Sweet.fire({ icon: 'error', title: 'Failed to fetch events', text: err.message })
      }
    })()
  }, [allUsers, rtdbEvents]) // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (displayUsers || !allUsers || rtdbEvents.length === 0) return
      const allEvents = selectedEvent === ''

      try {
        let usersDisplay = allUsers

        if (allEvents) {
          setUserColumns([
            { field: 'name', headerName: 'Name', width: 225 },
            { field: 'address', headerName: 'Address', width: 400 },
            { field: 'eventscsv', headerName: 'Events' }
          ])

          usersDisplay = allUsers.map(user => {
            const records = events.filter(e => e.address === user.address)
            const eventIds = [...new Set(records.map(e => e.id).sort())]
            const eventscsv = eventIds.join(',')
            return ({ ...user, eventscsv, records })
          })
        } else {
          setUserColumns([
            { field: 'name', headerName: 'Name', width: 225 },
            { field: 'address', headerName: 'Address', width: 225 },
            { field: 'code', headerName: 'Claim Code', width: 125 },
            { field: 'status', headerName: 'Claim Status', width: 125 },
            { field: 'tx', headerName: 'TX', width: 100 }
          ])

          usersDisplay = allUsers.filter(user => {
            return events.some(e => e.address === user.address && e.id === selectedEvent)
          }).map(user => {
            const event = events.find(e => e.address === user.address && e.id === selectedEvent)
            return ({
              id: `${user.address}_${event.code}`,
              name: event.username, address: user.address, code: event.code,
              status: event.status, tx: event.tx
            })
          })
        }

        setDisplayUsers(usersDisplay)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [allUsers, displayUsers, events, selectedEvent, rtdbEvents])

  async function getBalance () {
    const address = getBalanceAddress.current.querySelector('input').value
    if (!address) return
    const balance = ethers.utils.formatEther(await wallet.contracts.mtvrs.balanceOf(address))
    setGetBalanceResult(`${balance} MTVRS`)
  }

  async function transfer () {
    try {
      const address = transferAddress.current.querySelector('input').value
      const amount = ethers.utils.parseEther(transferAmount.current.querySelector('input')?.value)
      if (!address || !amount) return
      setTransferResult('Building..')
      const result = await wallet.contracts.mtvrs.transfer(address, amount, { gasPrice: wallet.provider.getGasPrice() })
      setTransferResult('Pending..')
      console.log({ result })
      await result.wait()
      setTransferResult('Complete')
    } catch (err) {
      console.error(err)
      Sweet.fire({ icon: 'error', title: 'Transaction Error', text: err.message })
    }
  }

  async function getCollectionBalance (collection) {
    const address = collectionData[collection]?.getBalanceAddress
    if (!address || !address.match(/^0x/)) throw new Error('Invalid address')
    const balance = await wallet.contracts.collections[collection]?.balanceOf(address)
    setCollectionResults(cr => ({ ...cr, [collection]: { balance: balance.toString() } }))
  }

  async function handleDiscordUserSelection (user) {
    setSelectedUser(user.row)
  }

  return (
    <>
      <NewUser db={db} rtdb={rtdb} selectedEvent={selectedEvent} events={rtdbEvents} setAllUsers={setAllUsers} showNewUserDialog={showNewUserDialog} setShowNewUserDialog={setShowNewUserDialog} />
      {selectedUser && <UserActions user={selectedUser} setUser={setSelectedUser} />}

      {!wallet.adminAddresses.includes(wallet.address?.toUpperCase()) && <h1>Access Denied</h1>}

      {
        wallet.adminAddresses.includes(wallet.address?.toUpperCase()) &&
          <div>
            <h1>Admin Dashboard</h1>
            <h2>{wallet.address.substr(0, 6) + '..' + wallet.address.slice(-4)}</h2>

            <Container maxWidth='md' className={classes.container}>
              {/* Discord User Functions */}
              <Accordion expanded={userlistExpanded} onChange={() => setUserlistExpanded(!userlistExpanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ marginTop: '0.5rem' }}>Discord User Functions</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                    <InputLabel id='event-selector-label'>Select Event</InputLabel>
                    <Select
                      id='event-selector'
                      label='Select Event'
                      labelId='event-selector-label'
                      value={selectedEvent}
                      onChange={e => { setDisplayUsers(null); setSelectedEvent(e.target.value) }}
                    >
                      <MenuItem value=''>All Events</MenuItem>
                      {
                        eventIDs.map(id => {
                          const event = rtdbEvents[id]
                          if (event) {
                            event.start = new Date(event.event_start)
                            event.end = new Date(event.event_end)
                          }

                          const eventData = event ? `
                            (${event.event_name}; ${Intl.DateTimeFormat('en-US').format(event.start)}
                            - ${Intl.DateTimeFormat('en-US').format(event.end)})
                          ` : ''

                          return (
                            <MenuItem key={id} value={id}>Event {id} {eventData}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>

                  <Button
                    fullWidth
                    variant='contained'
                    startIcon={<PersonAdd />}
                    sx={{ marginBottom: '1rem' }}
                    onClick={() => setShowNewUserDialog(true)}
                  >
                    {selectedEvent ? 'Add User to Event' : 'New User'}
                  </Button>

                  <DataGrid
                    autoHeight
                    rows={displayUsers || []}
                    columns={userColumns}
                    loading={!displayUsers}
                    density='compact'
                    onRowClick={handleDiscordUserSelection}
                  />
                </AccordionDetails>
              </Accordion>

              {/* MTVRS Contract Functions */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>MTVRS Contract Functions</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Card elevation={4}>
                        <CardHeader title='Get Balance' subheader='balanceOf(address)'></CardHeader>
                        <CardContent>
                          <Input fullWidth ref={getBalanceAddress} placeholder='Wallet Address' style={{ fontSize: '0.9rem', marginBottom: '1rem' }} />
                          {<Alert severity='info'>{getBalanceResult || 'Enter an address'}</Alert>}
                        </CardContent>
                        <CardActions>
                          <Button variant='contained' fullWidth onClick={getBalance}>Execute</Button>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Card elevation={4}>
                        <CardHeader title='Transfer' subheader='transfer(to, amount)'></CardHeader>
                        <CardContent>
                          <Input fullWidth ref={transferAddress} placeholder='Recipient Address' style={{ fontSize: '0.9rem', marginBottom: '1rem' }} />
                          <Input fullWidth ref={transferAmount} placeholder='MTVRS Amount' style={{ fontSize: '0.9rem', marginBottom: '1rem' }} />
                          {transferResult && <Alert severity='info'>{transferResult}</Alert>}
                        </CardContent>
                        <CardActions>
                          <Button variant='contained' fullWidth onClick={transfer}>Execute</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Tiger Collection Functions */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Collection Functions: <strong>Tiger</strong></Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Card elevation={4}>
                        <CardHeader title='Get Balance' subheader='balanceOf(address)'></CardHeader>
                        <CardContent>
                          <Input
                            fullWidth
                            onChange={e => setCollectionData(cd => ({ ...cd, Tiger: { ...cd.Tiger, getBalanceAddress: e.target.value } }))}
                            placeholder='Wallet Address'
                            style={{ fontSize: '0.9rem', marginBottom: '1rem' }} 
                          />
                          {collectionResults.Tiger?.balance && <Alert severity='info'>{collectionResults.Tiger?.balance}</Alert>}
                        </CardContent>
                        <CardActions>
                          <Button variant='contained' fullWidth onClick={() => getCollectionBalance('Tiger')}>Execute</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Container>
          </div>
      }
    </>
  )
}
