import { useContext, useEffect, useRef, useState } from 'react'
import { ethers } from 'ethers'
import { useInterval } from '@mantine/hooks'
import { useQuery, gql } from '@apollo/client'
import { styled } from '@mui/material/styles'

import Alert from '@mui/material/Alert'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import WalletContext from '../Context/wallet'
import { getAccountTokens } from '../Queries'

const Sweet = withReactContent(Swal)
const maxSpend = ethers.BigNumber.from(ethers.constants.MaxUint256)

const WalletButton = styled(Button)({ textTransform: 'none' })

export default function Collection(
  {name, parts, address}
  //name, item, imageUrl, itemRarity
) {
  const { wallet, setWallet } = useContext(WalletContext)
  const [vaultTokens, setVaultTokens] = useState()
  const [vaultTokenCounts, setVaultTokenCounts] = useState([])
  const [userInventory, setUserInventory] = useState()
  const [userInventoryCounts, setUserInventoryCounts] = useState(Array(parts.length))

  /**
   * items is an array of objects with [{name: "", item: "", ...}]
   * 
   */
  function getMintNumber(itemRarity) {
    switch (itemRarity) {
      case 'COMMON':
        return {number: "100,000", price: 1};
      case 'UNCOMMON':
        return { number: "10,000", price: 2 };
      case 'RARE':
        return { number: "5,000", price: 2.5 };
      case 'EPIC':
        return { number: "1,000", price: 3 };
      case 'LEGENDARY':
        return { number: "100", price: 4 };
      case 'MYTHIC':
        return { number: "10", price: 5 };
        default:
          return {};
    }
  }

  // --- Get vault counts
  const {
    loading: vaultTokensLoading,
    error: vaultTokensError,
    data: vaultTokensData
  } = useQuery(gql(getAccountTokens), {
    variables: { id: wallet.vault, collection: address, first: 1000 },
    pollInterval: 1000
  })

  useEffect(() => {
    if (!vaultTokensLoading && !vaultTokensError && vaultTokensData) setVaultTokens(vaultTokensData.nfts)
  }, [vaultTokensLoading, vaultTokensError, vaultTokensData])

  useEffect(() => {
    if (!vaultTokens) return
    let available = []

    if (vaultTokens.length === 0) {
      available = Array(parts.length).fill(0)
    } else {
      vaultTokens.forEach(token => { available[parseInt(token.item.blockchainId)] = 0 })
      vaultTokens.forEach(token => { available[parseInt(token.item.blockchainId)]++ })
    }

    setVaultTokenCounts(available)
  }, [vaultTokens]) // eslint-disable-line
  // --- End get vault counts

  // --- Get user inventory counts
  const {
    loading: inventoryTokensLoading,
    error: inventoryTokensError,
    data: inventoryTokensData,
    refetch: inventoryTokensRefetch
  } = useQuery(gql(getAccountTokens), {
    variables: { id: wallet.address, collection: address, first: 1000 },
    polling: 1000
  })

  useEffect(() => {
    if (!inventoryTokensLoading && !inventoryTokensError && inventoryTokensData) setUserInventory(inventoryTokensData.nfts)
  }, [inventoryTokensLoading, inventoryTokensError, inventoryTokensData])

  useEffect(() => {
    if (!userInventory) return
    let owned = Array(parts.length).fill(0)

    userInventory.forEach(token => { owned[parseInt(token.item.blockchainId)] = 0 })
    userInventory.forEach(token => { owned[parseInt(token.item.blockchainId)]++ })

    setUserInventoryCounts(owned)
  }, [userInventory]) // eslint-disable-line
  // --- End get user inventory counts

  return (
    <div>
      <div className='collectionContent'>
        <h1>{name}</h1>
        <h2 style={{ marginBottom: '2rem' }}>Collection</h2>

        <div className="divTable">
          <div className="divTableRow">
            {
              parts.map(({item, itemRarity, itemId, imageUrl}, index) => {
                const x = getMintNumber(itemRarity)

                return (
                  <CollectionItem
                    key={index}
                    item={item}
                    itemRarity={itemRarity}
                    itemId={itemId}
                    name={name}
                    x={x}
                    imageUrl={imageUrl}
                    parts={parts}
                    wallet={wallet}
                    setWallet={setWallet}
                    vaultTokens={vaultTokens}
                    vaultTokenCounts={vaultTokenCounts}
                    userInventory={userInventory}
                    userInventoryCounts={userInventoryCounts}
                    setUserInventoryCounts={setUserInventoryCounts}
                    inventoryTokensRefetch={inventoryTokensRefetch}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const CollectionItem = ({
  name, item, itemRarity, itemId, x, imageUrl, parts,
  vaultTokens, vaultTokenCounts, userInventory, setWallet,
  userInventoryCounts, setUserInventoryCounts, inventoryTokensRefetch
}) => {
  const [working, setWorking] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const { wallet, connectWallet, switchNetwork } = useContext(WalletContext)

  const availableRef = useRef()

  // Sometimes we get a transaction underpriced error when using default gas values
  const getProviderGas = () => ({ gasPrice: wallet.provider.getGasPrice() })

  const ellipsisInterval = useInterval(() => {
    if (!availableRef.current) return

    let newStatus
    const status = availableRef.current.textContent
    const occurrences = (status.match(/\./g) || []).length

    if (occurrences === 0) newStatus = '.   available'
    if (occurrences === 1) newStatus = '..  available'
    if (occurrences === 2) newStatus = '... available'
    if (occurrences === 3) newStatus = '    available'

    availableRef.current.innerHTML = newStatus.replace(/ /g, '&nbsp;')
  }, 500)

  useEffect(() => {
    ellipsisInterval.start()
    return ellipsisInterval.stop
  }, []) // eslint-disable-line

  // useEffect(() => {
  //   console.log({ userInventoryCounts })
  // }, [userInventoryCounts])

  function refreshInventoryCounts () {
    // Now that we are doing optimistic updates, let's wait a bit for the actual fetch
    // Before optimistic updates, this ran several times to catch the chain update
    // setTimeout(inventoryTokensRefetch, 500)
    // setTimeout(inventoryTokensRefetch, 1000)
    // setTimeout(inventoryTokensRefetch, 3000)
    // setTimeout(inventoryTokensRefetch, 5000)
    // setTimeout(inventoryTokensRefetch, 7000)
    setTimeout(inventoryTokensRefetch, 15000)
    setTimeout(inventoryTokensRefetch, 30000)
  }

  async function buyItem () {
    setWorking(true)

    try {
      const allowance = await wallet.contracts.mtvrs.allowance(wallet.address, wallet.contracts.market.address)

      const vaultItems = vaultTokens.filter(token => token.item.blockchainId === itemId.toString())
      if (vaultItems.length === 0) return Sweet.fire({ icon: 'error', text: 'This item is unavailable' })
      const token = vaultItems[0]

      if (allowance.isZero()) {
        await Sweet.fire({
          toast: true,
          icon: 'info',
          title: 'Marketplace Approval',
          text: 'You will need two transactions - this first one allows the Marketplace to transfer your $MTVRS. You should only need to do this once.'
        })

        const result = await wallet.contracts.mtvrs.approve(wallet.contracts.market.address, maxSpend, getProviderGas())
        await result.wait()

        await Sweet.fire({
          toast: true,
          icon: 'info',
          title: 'Buy Wearable',
          text: 'This final transaction will buy the wearable.'
        })
      }

      const result = await wallet.contracts.market.purchaseWearable(token.contractAddress, token.tokenId, getProviderGas())
      await result.wait()
      Sweet.fire({ icon: 'success', text: 'You have successfully purchased this item' })

      setUserInventoryCounts(uc => {
        const newCounts = [...uc]
        newCounts[token.item.blockchainId] = uc[token.item.blockchainId] + 1
        return newCounts
      })

      refreshInventoryCounts()

      setWallet(w => {
        let newBalance = parseFloat(w.balance) - x.price
        if (Number.isInteger(newBalance)) newBalance += '.0'

        return {
          ...w,
          oldBalance: w.balance,
          optimisticBalance: newBalance.toString(),
          balance: newBalance.toString()
        }
      })
    } catch (err) {
      let text
      console.error(err)

      switch (err.code) {
        case 4001:
          text = 'Transaction rejected'
          break
        default:
          text = err.data?.message || 'Please try again in a few moments.'
      }

      Sweet.fire({ icon: 'error', title: 'Transaction Error', text })
    } finally {
      setWorking(false)
    }
  }

  async function exchangeItem () {
    setWorking(true)

    try {
      const contract = wallet.contracts.collections.find(c => c.collectionData.name === name)
      if (userInventory.filter(token => token.item.blockchainId === itemId.toString()).length === 0) {
        return Sweet.fire({ icon: 'error', text: 'You do not own this item' })
      }

      const Selector = ({ name, item, blockchainId, tokenId, itemRarity }) => {
        const [swapParts, setSwapParts] = useState()
        const [exchangeSelected, setExchangeSelected] = useState()

        useEffect(() => {
          window.mtvrsExchangeItem = exchangeSelected
        }, [exchangeSelected])

        useEffect(() => {
          if (!swapParts) setSwapParts(parts.filter(p => itemId !== p.itemId && itemRarity === p.itemRarity))
        }, [item, itemRarity, swapParts])

        return (
          <>
            {
              swapParts?.length > 0 &&
                <>
                  <h1>Swap</h1>
                  <h2>{name} {item}</h2>
                  <h1>For</h1>
                </>
            }

            <div className="exchange-grid">
              {swapParts?.length === 0 && <Alert className='warning-no-items' severity='warning'>There are no equivalent items in this collection to swap</Alert>}
              {
                swapParts?.map(({ item: partItem, itemId: partItemId, itemRarity: partItemRarity, imageUrl }) => {
                  return (
                    <div key={`${partItem}_${itemId}_${itemRarity}`} onClick={() => setExchangeSelected({ partItem, partItemId })} className={`exchange-grid-item ${exchangeSelected?.partItemId === partItemId ? 'exchange-grid-item-selected' : ''}`}>
                      <strong>{name} {partItem}</strong>
                      <img src={imageUrl} className={`item-image ${itemRarity.toLowerCase()}-background`} alt="" />
                    </div>
                  )
                })
              }
            </div>
          </>
        )
      }

      Sweet.fire({
        html: <Selector name={name} item={item} itemRarity={itemRarity} />,
        width: '80%',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Swap',
        didRender: popup => {
          setTimeout(() => {
            const noItems = popup.querySelector('.warning-no-items')
            if (noItems) popup.querySelector('.swal2-confirm').remove()
          }, 50)
        },
        preConfirm: async () => {
          if (window.mtvrsExchangeItem === undefined) return false

          try {
            const userItems = userInventory.filter(token => token.item.blockchainId === itemId.toString())
            if (userItems.length === 0) return Sweet.fire({ icon: 'error', text: 'You do not own this item' })
            const myToken = userItems[0]

            const vaultItems = vaultTokens.filter(token => token.item.blockchainId === window.mtvrsExchangeItem.partItemId.toString())
            if (vaultItems.length === 0) return Sweet.fire({ icon: 'error', text: 'This item is unavailable' })
            const vaultToken = vaultItems[0]

            const approved = await contract.isApprovedForAll(wallet.address, wallet.contracts.market.address)

            if (!approved) {
              await Sweet.fire({
                toast: true,
                icon: 'info',
                title: 'Marketplace Approval',
                text: 'You will need two transactions - this first one allows the Marketplace to transfer your wearables. You should only need to do this once.'
              })

              const approveTx = await contract.setApprovalForAll(wallet.contracts.market.address, true, getProviderGas())
              await approveTx.wait()

              await Sweet.fire({
                toast: true,
                icon: 'info',
                title: 'Exchange Wearable',
                text: 'This final transaction will exchange the wearable.'
              })
            }

            const result = await wallet.contracts.market.exchangeWearable(vaultToken.contractAddress, vaultToken.tokenId, myToken.tokenId, getProviderGas())
            await result.wait()

            Sweet.fire({
              icon: 'success',
              text: `You have successfully exchanged your ${name} ${item} for a ${name} ${window.mtvrsExchangeItem.partItem}`
            })

            window.mtvrsExchangeItem = undefined

            setUserInventoryCounts(uc => {
              const newCounts = [...uc]
              newCounts[vaultToken.item.blockchainId] = uc[vaultToken.item.blockchainId] + 1
              newCounts[myToken.item.blockchainId] = uc[myToken.item.blockchainId] - 1
              return newCounts
            })

            refreshInventoryCounts()
          } catch (err) {
            let text
            console.error(err)

            switch (err.code) {
              case 4001:
                text = 'Transaction rejected'
                break
              default:
                text = err.data?.message || 'Please try again in a few moments.'
            }

            Sweet.fire({ icon: 'error', title: 'Transaction Error', text })
            return true
          }
        }
      })
    } catch (err) {
      let text
      console.error(err)

      switch (err.code) {
        case 4001:
          text = 'Transaction rejected'
          break
        default:
          text = err.data?.message || 'Please try again in a few moments.'
      }

      Sweet.fire({ icon: 'error', title: 'Transaction Error', text })
    } finally {
      setWorking(false)
    }
  }

  async function returnItem () {
    setWorking(true)

    try {
      const contract = wallet.contracts.collections.find(c => c.collectionData.name === name)
      const userItems = userInventory.filter(token => token.item.blockchainId === itemId.toString())
      if (userItems.length === 0) return Sweet.fire({ icon: 'error', text: 'You do not own this item' })

      const answer = await Sweet.fire({
        icon: 'question',
        title: 'Are you sure?',
        text: `A 25% fee is incurred on returns. You will receive ${x.price - (x.price * 0.25)} $MTVRS for ${name} ${item}.`,
        showDenyButton: true,
        confirmButtonText: 'Yes'
      })

      if (answer.isDenied) return

      const token = userItems[0]
      const approved = await contract.isApprovedForAll(wallet.address, wallet.contracts.market.address)

      if (!approved) {
        await Sweet.fire({
          toast: true,
          icon: 'info',
          title: 'Marketplace Approval',
          text: 'You will need two transactions - this first one allows the Marketplace to transfer your wearables. You should only need to do this once.'
        })

        const approveTx = await contract.setApprovalForAll(wallet.contracts.market.address, true, getProviderGas())
        await approveTx.wait()

        await Sweet.fire({
          toast: true,
          icon: 'info',
          title: 'Return Wearable',
          text: 'This final transaction will return the wearable.'
        })
      }

      const result = await wallet.contracts.market.returnWearable(token.contractAddress, token.tokenId, getProviderGas())
      await result.wait()
      Sweet.fire({ icon: 'success', text: 'This item has been returned for a refund' })

      setUserInventoryCounts(uc => {
        const newCounts = [...uc]
        if (uc[token.item.blockchainId] !== 0) newCounts[token.item.blockchainId] = uc[token.item.blockchainId] - 1
        return newCounts
      })

      refreshInventoryCounts()

      setWallet(w => {
        let newBalance = parseFloat(w.balance) + (x.price - (x.price * 0.25))
        if (Number.isInteger(newBalance)) newBalance += '.0'

        return {
          ...w,
          oldBalance: w.balance,
          optimisticBalance: newBalance.toString(),
          balance: newBalance.toString()
        }
      })
    } catch (err) {
      let text
      console.error(err)

      switch (err.code) {
        case 4001:
          text = 'Transaction rejected'
          break
        default:
          text = err.data?.message || 'Please try again in a few moments.'
      }

      Sweet.fire({ icon: 'error', title: 'Transaction Error', text })
    } finally {
      setWorking(false)
    }
  }

  return (
    <div className="divTableCell">
      <h3>{name}<br />{item}</h3>

      <div>
        {!imageLoaded && <div className="lds-dual-ring"></div>}
        <Badge
          badgeContent={typeof userInventoryCounts[itemId] === 'number' ? userInventoryCounts[itemId].toString() : <>...</>}
          color='primary'
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className='item-count-badge'
        >
          <img hidden={!imageLoaded} onLoad={() => setImageLoaded(true)} onError={err => { console.error(err); setImageLoaded(false) }} src={imageUrl} className={`item-image ${itemRarity.toLowerCase()}-background`} alt="" />
        </Badge>
      </div>

      <div className="divTable" style={{ textAlign: "center", border: "0px solid #fff",paddingTop: "10px" }}>
        <div className="divTableRow">
          <div className="divTableCell">
            <p style={{ marginBottom: '1rem' }}>
              {itemRarity}
              <br />
              {x.number} mints
              <br />
              {typeof vaultTokenCounts[itemId] === 'undefined' && <span ref={availableRef}>... available</span>}

              {
                typeof vaultTokenCounts[itemId] !== 'undefined' &&
                !isNaN(vaultTokenCounts[itemId]) &&
                  <>
                    {vaultTokenCounts[itemId]?.toString()} available
                  </>
              }
              <br />
              {x.price} MTVRS
            </p>

            {/* {!working && !wallet.connected && <button className="button connectbutton" onClick={connectWallet}>Connect</button>} */}
            {!working && !wallet.connected && <WalletButton className='btn connectbutton' variant='contained' onClick={connectWallet}>Connect</WalletButton>}

            {
              !working && wallet.connected && wallet.chainId !== 137 &&
                <WalletButton className='btn connectbutton' variant='contained' onClick={switchNetwork}>Switch to Polygon</WalletButton>
            }

            {
              !working && wallet.connected && wallet.chainId === 137 &&
                <>
                  <WalletButton className='btn buybutton' variant='contained' onClick={buyItem}>Buy</WalletButton>
                  <WalletButton className='btn exchangebutton' variant='contained' onClick={exchangeItem}>Exchange</WalletButton>
                  <WalletButton className='btn returnbutton' variant='contained' onClick={returnItem}>Return</WalletButton>
                </>
            }

            {working && <CircularProgress />}
          </div>
        </div>
      </div>
    </div>
  )
}
