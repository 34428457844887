import { useCallback, useEffect, useRef, useState } from 'react'
import { ethers } from 'ethers'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import topbar from 'topbar'

import Package from '../package.json'
import WalletContext from './Context/wallet'
import MarketABI from './Assets/abi/MarketABI'
import MtvrsABI from './Assets/abi/MtvrsABI'
import { getCollections, getContracts } from './Assets/_collections'

import TopNav from './Components/TopNav'
import Header from './Components/Header'
import Home from './Components/Home'
import Collection from './Components/Collection'
import Footer from './Components/Footer'
import Admin from './Components/Admin/Admin'

import './App.css'

topbar.show()
const basename = process.env.REACT_APP_BASENAME || ''
const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, 'any') : null
const theme = createTheme({ palette: { mode: 'dark' } })
const collections = getCollections(basename)

export default function App() {
  const [optimisticTicks, setOptimisticTicks] = useState(0)
  const [initialRequestDone, setInitialRequestDone] = useState(false)

  const [wallet, setWallet] = useState({
    provider,
    chainId: 1,
    connected: false,
    address: null,
    signer: null,
    balance: null,
    refreshBalance: null,
    vault: process.env.REACT_APP_VAULT_ADDRESS,
    adminAddresses: process.env.REACT_APP_ADMIN_ADDRESSES?.toUpperCase().split(',') || []
  })

  const walletRef = useRef()
  walletRef.current = wallet

  const updateBalance = useCallback(async () => {
    try {
      if (!walletRef.current.contracts) return
      const balance = ethers.utils.formatEther(await walletRef.current.contracts.mtvrs.balanceOf(walletRef.current.address))
      setWallet(w => ({ ...w, balance }))
      return balance
    } catch (err) {
      console.error(err)
    }
  }, [walletRef])

  const connectWallet = useCallback(async () => {
    const accounts = await provider.send('eth_requestAccounts', [])
    await provider.send('eth_accounts', []) // this seems to fix a weird issue where metamask does not popup and no connection is made

    if (accounts?.length > 0) {
      const { chainId } = await provider.getNetwork()
      const signer = provider.getSigner()
      const address = accounts[0]

      const contracts = {
        mtvrs: new ethers.Contract(process.env.REACT_APP_CONTRACT_MTVRS, MtvrsABI, signer),
        market: new ethers.Contract(process.env.REACT_APP_CONTRACT_MARKET, MarketABI, signer),
        collections: getContracts(signer)
      }

      provider.on('block', updateBalance)
      setWallet(w => ({ ...w, connected: true, address, chainId, contracts, signer }))
    } else {
      setWallet(w => ({ ...w, connected: false, address: null }))
    }
  }, [updateBalance])

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }]
      })
    } catch (err) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            rpcUrls: ['https://rpc-mainnet.matic.network/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            }
        }]
      })
    }

    try {
      const balance = ethers.utils.formatEther(await wallet.contracts.mtvrs.balanceOf(wallet.address))
      setWallet(w => ({ ...w, balance }))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    console.log(`MTVRS Market v${Package.version}`)

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async accounts => {
        const { chainId } = await provider.getNetwork()
        setWallet(w => ({ ...w, address: accounts[0] }))
        if (chainId === 137) {
          await updateBalance()
        }
      })

      window.ethereum.on('chainChanged', chainId => {
        setWallet(w => ({ ...w, chainId: parseInt(chainId) }))
      })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const { balance, oldBalance, optimisticBalance } = wallet

    // console.log('optimisticTicks', optimisticTicks)
    // console.log('optimisticBalance', optimisticBalance)
    // console.log('oldBalance', oldBalance)
    // console.log('balance', balance)
    // console.log('optimisticBalance === balance', optimisticBalance === balance)

    if (!optimisticBalance) return
    if (balance === oldBalance) return setOptimisticTicks(v => v + 1)
    else if (optimisticTicks > 0) {
      setOptimisticTicks(0)
      setWallet(w => ({ ...w, optimisticBalance: null, oldBalance: null }))
    }
  }, [wallet.balance, wallet.block]) // eslint-disable-line

  // useEffect(() => {
  //   console.log({ optimisticBalance: wallet.optimisticBalance })
  // }, [wallet.optimisticBalance])

  useEffect(() => {
    if (provider && !initialRequestDone) {
      connectWallet()
      setInitialRequestDone(true)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!wallet?.connected) return
    if (!wallet.address) setWallet(w => ({ ...w, connected: false, balance: null }))
  }, [wallet])

  topbar.hide()

  return (
    <WalletContext.Provider value={{ wallet, setWallet, connectWallet, switchNetwork }}>
      <ThemeProvider theme={theme}>
        <div className='App'>
            <Router basename={basename}>
              <TopNav />
              <Header basename={basename} />
              <div className='content'>
                <Routes>
                  <Route exact path='/' element={<Home basename={basename} />}></Route>
                  <Route exact path='/admin' element={<Admin />} />
                  {
                    collections.map(collection => (
                      <Route
                        exact
                        key={collection.name}
                        path={`/collections/${collection.safeName}`}
                        element={<Collection {...collection} />}
                      />
                    ))
                  }
                </Routes>
              </div>
            </Router>
          <Footer basename={basename} />
        </div>
      </ThemeProvider>
    </WalletContext.Provider>
  )
}