import { useEffect, useState } from 'react'
import { collection as fireCollection, collectionGroup as fireCollectionGroup, doc, getDocs, query, setDoc, where } from 'firebase/firestore'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import LoadingButton from '@mui/lab/LoadingButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

export default function NewUser ({ db, rtdb, selectedEvent, events, setAllUsers, showNewUserDialog, setShowNewUserDialog }) {
  const [alreadyClaimed, setAlreadyClaimed] = useState(false)
  const [loading, setLoading] = useState(false)

  const [username, setUsername] = useState('')
  const [address, setAddress] = useState('')
  const [eventId, setEventId] = useState('')
  const [status, setStatus] = useState('Claimed')
  const [event, setEvent] = useState('')
  const [code, setCode] = useState('')
  const [tx, setTx] = useState('')

  useEffect(() => {
    if (!showNewUserDialog) {
      setAlreadyClaimed(false)
      setLoading(false)
      setUsername('')
      setAddress('')
      setStatus('Claimed')
      setEventId('')
      setEvent()
      setCode('')
      setTx('')
      return
    }

    setEventId(selectedEvent)
  }, [showNewUserDialog, selectedEvent])

  // async function migrateDb () {
  //   const userCollection = fireCollection(db, process.env.REACT_APP_FIREBASE_USER_COLLECTION)
  //   const q = query(userCollection)
  //   const userSnapshot = await getDocs(q)
  //   const userList = userSnapshot.docs.map(user => ({ ...user.data(), id: user.id }))

  //   for (const user of userList) {
  //     const eventsCollection = fireCollection(db, `${process.env.REACT_APP_FIREBASE_USER_COLLECTION}/${user.address}/events`)
  //     const q = query(eventsCollection)
  //     const eventsSnapshot = await getDocs(q)
  //     const eventsList = eventsSnapshot.docs.map(event => ({ ...event.data(), id: event.id }))

  //     for (const event of eventsList) {
  //       await setDoc(doc(db, `${process.env.REACT_APP_FIREBASE_USER_COLLECTION}/${user.address}/events`, event.id), { ...event, username: user.name })
  //     }
  //   }
  // }

  // useEffect(() => {
    // console.log(migrateDb)
    // migrateDb()
  // }, []) // eslint-disable-line

  useEffect(() => {
    setAlreadyClaimed(false)
  }, [event])

  async function addUser () {
    try {
      if (!username || username === '') throw new Error('Must specify username')
      if (!address || address === '') throw new Error('Must specify address')
      if (!eventId || eventId === '' || isNaN(eventId)) throw new Error('Must specify event')
      if (!code || code === '') throw new Error('Must specify code')

      const user = { username, address }
      const evt = { eventId, username, code, status, tx }

      setLoading(true)
      await setDoc(doc(db, process.env.REACT_APP_FIREBASE_USER_COLLECTION, user.address), user)
      await setDoc(doc(db, `${process.env.REACT_APP_FIREBASE_USER_COLLECTION}/${user.address}/events`, eventId), evt)
      
      setAllUsers(null)
      setShowNewUserDialog(false)
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    } finally {
      setLoading(false)
    }
  }

  async function addressChanged (newAddress) {
    setAddress(newAddress)
    if (!event) return

    setLoading(true)
    const eventsGroup = fireCollectionGroup(db, 'events')
    const eventsQuery = query(eventsGroup)
    const eventsSnapshot = await getDocs(eventsQuery)
    const eventsList = eventsSnapshot.docs
      .filter(doc => doc.ref.path.split('/')[0] === process.env.REACT_APP_FIREBASE_USER_COLLECTION)
      .map(doc => ({ ...doc.data(), event: doc.id, address: doc.ref.parent.parent.id }))
      .filter(doc => doc.event === event && doc.address.toLowerCase() === newAddress.toLowerCase())

    setLoading(false)

    if (eventsList.length > 0) {
      setAlreadyClaimed(true)
      setUsername(eventsList[0].username || '')
      setStatus(eventsList[0].status)
      setEventId(eventsList[0].event)
      setCode(eventsList[0].code || '')
      setTx(eventsList[0].tx || '')
    }
  }

  async function claimCodeChanged (newCode) {
    setCode(newCode.toUpperCase())
    setAlreadyClaimed(false)
    setLoading(true)

    const event = events?.filter(f => f).find((event, index) => {
      event.id = index + 1
      return index + 1 === parseInt(eventId)
    })

    if (!event) return setLoading(false)
    const address = event.codes[newCode]
    if (!address) return setLoading(false)

    setAddress(address)

    const eventsGroup = fireCollectionGroup(db, 'events')
    const eventsQuery = query(eventsGroup)
    const eventsSnapshot = await getDocs(eventsQuery)
    console.log(eventsSnapshot)
    const eventsList = eventsSnapshot.docs
      .filter(doc => doc.ref.path.split('/')[0] === process.env.REACT_APP_FIREBASE_USER_COLLECTION)
      .map(doc => ({ ...doc.data(), event: doc.id, address: doc.ref.parent.parent.id }))
      .filter(doc => doc.code === newCode.toUpperCase())
      .filter(doc => doc.event === eventId.toString())

    setLoading(false)

    if (eventsList.length > 0) {
      setAlreadyClaimed(true)
      setUsername(eventsList[0].username || '')
      setStatus(eventsList[0].status)
      setTx(eventsList[0].tx || '')
    } else {
      setAlreadyClaimed(false)
    }
  }

  async function usernameChanged (newUsername) {
    setUsername(newUsername)

    setLoading(true)
    const userCollection = fireCollection(db, process.env.REACT_APP_FIREBASE_USER_COLLECTION)
    const q = query(userCollection, where('name', '==', newUsername))
    const userSnapshot = await getDocs(q)
    const userList = userSnapshot.docs.map(user => ({ ...user.data(), name: user.id }))
    setLoading(false)

    console.log({ userList, userSnapshot })

    if (userList.length > 0) {
      setAddress(userList[0].address)
      // checkClaim(userList)
    }
  }

  async function txChanged (newTx) {
    setTx(newTx)
    setStatus('Sent')
  }

  return (
    <Dialog maxWidth='md' fullWidth onClose={() => setShowNewUserDialog(false)} open={showNewUserDialog}>
      <DialogTitle>
        New User
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Event'
          variant='filled'
          value={eventId || ''}
          onChange={e => setEventId(e.target.value)}
          helperText={alreadyClaimed ? 'User has already claimed a code for this event' : ''}
          error={alreadyClaimed}
        />

        <TextField
          fullWidth
          label='Discord Username'
          variant='filled'
          value={username}
          onChange={e => usernameChanged(e.target.value)}
          sx={{ marginTop: '1rem' }}
        />

        <TextField
          fullWidth
          label='Claim Code'
          variant='filled'
          value={code}
          onChange={e => claimCodeChanged(e.target.value)}
          sx={{ marginTop: '1rem' }}
        />

        <TextField
          fullWidth
          label='TX ID'
          variant='filled'
          value={tx}
          onChange={e => txChanged(e.target.value)}
          sx={{ marginTop: '1rem' }}
        />

        <FormControl fullWidth sx={{ marginTop: '1rem' }}>
          <InputLabel id='claim-status-label'>Claim Status</InputLabel>
          <Select
            label='Claim Status'
            labelId='claim-status-label'
            variant='filled'
            value={status}
            onChange={e => setStatus(e.target.value)}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value='Claimed'>Claimed</MenuItem>
            <MenuItem value='Sent'>Sent</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label='Wallet Address'
          variant='filled'
          value={address}
          onChange={e => addressChanged(e.target.value)}
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        />

        <LoadingButton
          fullWidth
          loading={loading}
          disabled={alreadyClaimed || loading}
          onClick={addUser}
          color='primary'
          variant='contained'
        >
          Add user
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}
