import { useContext, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ethers } from 'ethers'

import LoadingButton from '@mui/lab/LoadingButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Input from '@mui/material/Input'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import WalletContext from '../../Context/wallet'

export default function UserActions ({ user, setUser }) {
  const { wallet } = useContext(WalletContext)
  const [loading, setLoading] = useState(false)
  const [sendAmount, setSendAmount] = useState(0)
  
  async function send () {
    if (!sendAmount || sendAmount <= 0) throw new Error('Invalid send amount')
    setLoading(true)

    try {
      const result = await wallet.contracts.mtvrs.transfer(user.address, ethers.utils.parseEther(sendAmount), { gasPrice: wallet.provider.getGasPrice() })
      await result.wait()
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog maxWidth='md' fullWidth onClose={() => setUser(null)} open={Boolean(user)}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <strong style={{ fontSize: '1.25rem' }}>{user.name}</strong>
          <CopyToClipboard text={user.name}>
            <ContentCopyIcon sx={{ marginLeft: '1rem', cursor: 'pointer' }} />
          </CopyToClipboard>
        </div>

        <div>
          <strong style={{ fontSize: '1.25rem' }}>{user.address.substr(0, 6) + '..' + user.address.slice(-4)}</strong>
          <CopyToClipboard text={user.address}>
            <ContentCopyIcon sx={{ marginLeft: '1rem', cursor: 'pointer' }} />
          </CopyToClipboard>
        </div>
      </DialogTitle>
      <DialogContent>
        <Paper style={{ padding: '1rem', marginBottom: '2rem', textAlign: 'left' }}>
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper sx={{ padding: '1rem', display: (user.records || user.status === 'Sent') ? 'none' : 'block' }}>
              <h3>Send MTVRS</h3>
              <Input
                autoFocus
                fullWidth
                type='number'
                placeholder='Amount to send'
                onChange={e => setSendAmount(e.target.value)}
                sx={{ color: 'white', marginBottom: '1rem' }}
              />

              <LoadingButton
                fullWidth
                loading={loading}
                disabled={isNaN(sendAmount) || sendAmount === '' || sendAmount === 0}
                color='primary'
                variant='contained'
                onClick={send}
              >
                Send {sendAmount} MTVRS to {user.name}
              </LoadingButton>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
